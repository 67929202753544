@font-face
  font-family: 'HelveticaNeueCyr'
  font-weight: 300
  font-style: normal

  font-display: swap
  src: url('../fonts/HelveticaNeueCyr-Light.ttf')

@font-face
  font-family: 'HelveticaNeueCyr'
  font-weight: 400
  font-style: normal

  font-display: swap
  src: url('../fonts/HelveticaNeueCyr-Roman.ttf')

@font-face
  font-family: 'HelveticaNeueCyr'
  font-weight: 500
  font-style: normal

  font-display: swap
  src: url('../fonts/HelveticaNeueCyr-Medium.ttf')

@font-face
  font-family: 'HelveticaNeueCyr'
  font-weight: 700
  font-style: normal

  font-display: swap
  src: url('../fonts/HelveticaNeueCyr-Bold.ttf')
