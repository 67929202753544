@charset "UTF-8";
@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Light.ttf");
}
@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Roman.ttf");
}
@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Medium.ttf");
}
@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Bold.ttf");
}
* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  background-color: transparent;
  text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: none;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  overflow: visible;
  box-sizing: content-box;
  height: 0;
}

button {
  cursor: pointer;
}

button,
input,
select,
textarea {
  margin: 0;
  outline: none;
  font: inherit;
}

optgroup {
  font-weight: bold;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
  border: 1px solid #c0c0c0;
}

legend {
  display: table;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  color: inherit;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  appearance: textfield;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  appearance: none;
}

::-webkit-input-placeholder {
  opacity: 0.54;
  color: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

body {
  color: #1F1F1F;
  font: 400 18px "HelveticaNeueCyr";
}

section {
  padding: 120px 0;
}
@media (max-width: 767px) {
  section {
    padding: 60px 0;
  }
}

p {
  margin: 0;
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.h1,
.h2 {
  position: relative;
  margin: 0;
  font-size: 52px;
  font-weight: 700;
  line-height: 60px;
}
@media (max-width: 767px) {
  .h1,
.h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
.h1::after,
.h2::after {
  padding-left: 10px;
  content: "––";
  color: #B1021B;
  -webkit-text-stroke: 2px #B1021B;
}

.h2 {
  font-size: 48px;
  line-height: 48px;
}

.container {
  width: 100%;
  max-width: 1332px;
  margin: 0 auto;
  padding: 0 16px;
}

.input {
  height: 53px;
  padding: 0 20px;
  border: 1px solid #BBBBBB;
  border-radius: 0;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
}
.input::placeholder {
  opacity: 1;
  color: #BBBBBB;
}

.button {
  height: 53px;
  padding: 0 32px;
  transition: 0.1s ease-out;
  text-transform: uppercase;
  color: white;
  border: none;
  background: #B1021B;
  font-size: 14px;
  font-weight: 700;
}
.button:hover {
  transition: 0.2s ease-in;
  filter: contrast(1.3);
}

.form {
  display: grid;
  grid-template-columns: 35% auto 35%;
}
@media (max-width: 767px) {
  .form {
    grid-template-columns: 55% 45%;
  }
}
@media (max-width: 575px) {
  .form {
    grid-template-columns: 1fr;
  }
}
.form__button {
  margin-left: -1px;
}
@media (max-width: 575px) {
  .form__button {
    margin-top: -1px;
    margin-left: 0;
  }
}
.form__privicy {
  align-self: center;
  margin-left: 20px;
  color: #545454;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}
@media (max-width: 767px) {
  .form__privicy {
    margin-top: 10px;
    margin-left: 0;
    grid-column: 1/3;
  }
}
@media (max-width: 575px) {
  .form__privicy {
    grid-column: unset;
  }
}

.header {
  position: relative;
  z-index: 2;
  padding: 20px 0;
  color: white;
  background: #1F1F1F;
}
@media (max-width: 767px) {
  .header {
    padding: 0;
  }
}
.header__container {
  display: grid;
  grid: "logo menu menu phone" 50px "logo menu menu phone" 50px/1fr 1fr 1fr 1fr;
}
@media (max-width: 1199px) {
  .header__container {
    grid: "logo menu phone" 50px "logo menu phone" 50px/1fr 40% 1fr;
  }
}
@media (max-width: 767px) {
  .header__container {
    grid: "logo phone" 35px "logo phone" 35px/auto auto;
  }
}
.header__cell {
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  margin: -2px;
  border: 1px solid #545454;
}
@media (max-width: 767px) {
  .header__cell {
    border: none;
  }
}
.header__left {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: logo;
}
@media (max-width: 767px) {
  .header__left {
    justify-content: start;
  }
}
@media (max-width: 767px) {
  .header__logo {
    height: 36px;
  }
}
.header__menu {
  display: grid;
  grid-area: menu;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767px) {
  .header__menu {
    display: none;
  }
}
.header__menu-item {
  padding: 0 20px;
  transition: 0.1s ease-out;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
}
.header__menu-item:hover {
  transition: 0.2s ease-in;
  color: #BBBBBB;
}
.header__right {
  display: flex;
  align-items: center;
  padding-right: 32px;
  justify-items: flex-end;
  grid-area: phone;
}
@media (max-width: 991px) {
  .header__right {
    justify-content: center;
    padding: 0;
  }
}
.header__phone {
  display: grid;
  margin-left: auto;
  gap: 5px;
  grid: "icon phone" "icon text"/20px auto;
}
@media (max-width: 991px) {
  .header__phone {
    margin-left: 0;
    grid: "icon phone" "icon text"/auto;
  }
}
@media (max-width: 767px) {
  .header__phone {
    margin-left: auto;
  }
}
.header__phone-icon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  fill: #B1021B;
  grid-area: icon;
}
@media (max-width: 991px) {
  .header__phone-icon {
    display: none;
  }
}
.header__phone-link {
  transition: 0.1s ease-out;
  text-decoration: none;
  color: white;
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  grid-area: phone;
}
.header__phone-link:hover {
  transition: 0.2s ease-in;
  color: #B1021B;
}
.header__phone-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  grid-area: text;
}

.heroscreen {
  position: relative;
  overflow: hidden;
  padding: 160px 0;
  background: url(../images/heroscreen__background.jpg) no-repeat center/cover;
}
@media (max-width: 991px) {
  .heroscreen {
    padding: 60px 0;
  }
}
.heroscreen__container {
  display: grid;
  grid-template-columns: 7fr 5fr;
}
@media (max-width: 991px) {
  .heroscreen__container {
    grid-template-columns: 1fr;
  }
}
.heroscreen__item {
  padding: 30px;
  background: white;
}
@media (max-width: 767px) {
  .heroscreen__item {
    padding: 15px;
  }
}
.heroscreen__image {
  position: absolute;
  bottom: 0;
  height: 100%;
}
.heroscreen__image-wrapper {
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  .heroscreen__image-wrapper {
    display: none;
  }
}
.heroscreen__title {
  margin-bottom: 5px;
}
.heroscreen__subtitle {
  display: inline-block;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 300;
  line-height: 28px;
}
@media (max-width: 767px) {
  .heroscreen__subtitle {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 24px;
  }
}

.advantages {
  padding: 60px 0 0;
}
@media (max-width: 767px) {
  .advantages {
    padding-top: 30px;
  }
}
.advantages__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
  .advantages__container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 575px) {
  .advantages__container {
    grid-template-columns: 1fr;
  }
}
.advantages__item {
  display: grid;
  align-items: center;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  margin: -2px;
  padding: 30px;
  border: 1px solid #BBBBBB;
  gap: 20px;
  grid-template-columns: auto 1fr;
}
@media (max-width: 991px) {
  .advantages__item {
    padding: 15px;
    gap: 10px;
  }
}
@media (max-width: 767px) {
  .advantages__item:last-of-type {
    grid-column: 1/3;
  }
}
@media (max-width: 575px) {
  .advantages__item:last-of-type {
    grid-column: unset;
  }
}
.advantages__item-icon {
  width: 66px;
  height: 66px;
}
@media (max-width: 991px) {
  .advantages__item-icon {
    width: 36px;
    height: 36px;
  }
}

.pricelist__container {
  display: grid;
  gap: 30px;
}
@media (max-width: 767px) {
  .pricelist__container {
    gap: 15px;
  }
}
.pricelist__main {
  display: grid;
  grid: "first third fourth fourth" "second third fifth sixth"/1fr 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 20px;
}
@media (max-width: 1199px) {
  .pricelist__main {
    grid: "first third" "second third" "fourth fourth" "sixth fifth"/1fr 1fr;
  }
}
@media (max-width: 767px) {
  .pricelist__main {
    grid: "first second" "third fourth" "fifth sixth"/1fr 1fr;
    gap: 16px;
  }
}
@media (max-width: 575px) {
  .pricelist__main {
    grid: "first" "second" "third" "fourth" "fifth" "sixth"/1fr;
  }
}
.pricelist__item {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 20px;
  cursor: pointer;
  transition: 0.1s ease-out;
  background: url(../images/pricelist__item-image--background.svg) no-repeat right bottom/20%;
  background-color: #1F1F1F;
  row-gap: 30px;
}
@media (max-width: 767px) {
  .pricelist__item {
    overflow: hidden;
    align-items: end;
    max-height: 200px;
  }
}
.pricelist__item:hover {
  transition: 0.3s ease-in;
  background: url(../images/pricelist__item-image--background.svg) no-repeat right bottom/80%;
  background-color: #B1021B;
}
.pricelist__item--washer {
  grid-area: first;
}
.pricelist__item--dishwasher {
  grid-area: second;
}
.pricelist__item--fridge {
  grid-area: third;
}
.pricelist__item--coffeemachine {
  grid-area: fourth;
}
.pricelist__item--hob {
  grid-area: fifth;
}
.pricelist__item--oven {
  grid-area: sixth;
}
.pricelist__item-title {
  display: grid;
  align-items: center;
  width: 100%;
  margin: 0;
  color: white;
  font-size: 20px;
  font-weight: 500;
  grid-template-columns: auto 1fr;
  gap: 10px;
}
.pricelist__item-title-line {
  width: auto;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
}
.pricelist__item-image {
  margin-top: auto;
  margin-left: auto;
}
@media (max-width: 575px) {
  .pricelist__item-image {
    margin-left: 100px;
  }
}

.cta {
  padding: 0;
  color: white;
  background: url(../images/cta__background.jpg) no-repeat center/cover;
}
.cta__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
@media (max-width: 767px) {
  .cta__container {
    grid-template-columns: 1fr;
  }
}
.cta__image {
  position: absolute;
}
.cta__image-wrapper {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: center;
}
@media (max-width: 767px) {
  .cta__image-wrapper {
    display: none;
  }
}
.cta__main {
  padding: 120px 0;
}
@media (max-width: 767px) {
  .cta__main {
    padding: 60px 0;
  }
}
.cta__title {
  margin-bottom: 5px;
}
.cta__subtitle {
  display: inline-block;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 400;
}
@media (max-width: 991px) {
  .cta__subtitle {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .cta__subtitle {
    font-size: 20px;
  }
}
.cta__form {
  grid-template-columns: 55% auto;
  row-gap: 10px;
}
@media (max-width: 767px) {
  .cta__form {
    row-gap: 0;
  }
}
.cta__form .form__input {
  color: white;
}
.cta__form .form__privicy {
  margin: 0;
  color: #BBBBBB;
  grid-column: span 3;
}
@media (max-width: 767px) {
  .cta__form .form__privicy {
    margin-top: 10px;
    grid-column: inherit;
  }
}

.about {
  overflow: hidden;
  padding-bottom: 0;
}
.about__container {
  display: grid;
  grid: "sidebar main main main"/1fr 1fr 1fr 1fr;
  column-gap: 24px;
}
@media (max-width: 991px) {
  .about__container {
    grid: "sidebar" "main"/1fr;
  }
}
.about__sidebar {
  grid-area: sidebar;
}
@media (max-width: 991px) {
  .about__sidebar {
    margin-bottom: 20px;
  }
}
.about__main {
  grid-area: main;
}
.about__title {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .about__title {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .about__advantages {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.about__advantages-item {
  display: grid;
  align-items: center;
  max-width: 276px;
  padding: 16px 0;
  border-bottom: 1px solid #BBBBBB;
  column-gap: 10px;
  grid-template-columns: 40px 1fr;
}
@media (max-width: 991px) {
  .about__advantages-item {
    max-width: unset;
  }
}
@media (max-width: 575px) {
  .about__advantages-item {
    font-size: 16px;
    grid-template-columns: 30px 1fr;
  }
}
@media (max-width: 991px) {
  .about__advantages-item:nth-of-type(n+3) {
    border-bottom: none;
  }
}
.about__advantages-item-icon {
  width: 40px;
  height: 40px;
}
@media (max-width: 575px) {
  .about__advantages-item-icon {
    width: 30px;
    height: 30px;
  }
}
.about__images {
  display: flex;
  margin-bottom: 40px;
  column-gap: 24px;
}
@media (max-width: 991px) {
  .about__images {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 575px) {
  .about__images {
    grid-template-columns: 1fr;
  }
}
.about__images-item {
  max-width: 416px;
}
@media (max-width: 991px) {
  .about__images-item {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .about__images-item {
    max-width: unset;
    height: 250px;
    object-fit: cover;
    object-position: top;
  }
}
@media (max-width: 1199px) {
  .about__images-item:last-of-type {
    display: none;
  }
}
@media (max-width: 575px) {
  .about__images-item:nth-of-type(2) {
    display: none;
  }
}
.about__text {
  width: 100vw;
  padding-bottom: 40px;
  border-bottom: 1px solid #BBBBBB;
}
@media (max-width: 1199px) {
  .about__text {
    width: calc(100% + 15px);
  }
}
@media (max-width: 991px) {
  .about__text {
    width: 100%;
    border-bottom: none;
  }
}
.about__text b {
  display: inline-block;
  margin-bottom: 10px;
}
@media (max-width: 1199px) {
  .about__text br {
    display: none;
  }
}

@media (max-width: 767px) {
  .guarantee {
    padding-top: 0;
  }
}
.guarantee__item {
  display: grid;
  overflow: hidden;
  border: 1px solid #BBBBBB;
  grid-template-columns: 45% 50% 5%;
}
@media (max-width: 1199px) {
  .guarantee__item {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .guarantee__item {
    grid-template-columns: 1fr;
  }
}
.guarantee__content {
  padding: 120px 50px;
}
@media (max-width: 767px) {
  .guarantee__content {
    padding: 20px 20px;
  }
}
.guarantee__subtitle {
  display: inline-block;
  margin-bottom: 35px;
  font-size: 28px;
  font-weight: 300;
}
@media (max-width: 767px) {
  .guarantee__subtitle {
    margin-bottom: 16px;
  }
}
.guarantee__image {
  height: 100%;
}
@media (max-width: 767px) {
  .guarantee__image {
    display: none;
  }
}

.contacts {
  color: white;
  background: url(../images/contacts__background.jpg) no-repeat center/cover;
}
.contacts__main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 550px;
  row-gap: 30px;
}
@media (max-width: 767px) {
  .contacts__main {
    column-gap: 20px;
    row-gap: 20px;
  }
}
.contacts__title {
  margin-bottom: 30px;
}
.contacts__form {
  grid-template-columns: 55% 45%;
  row-gap: 10px;
}
@media (max-width: 575px) {
  .contacts__form {
    row-gap: unset;
  }
}
.contacts__form .form__privicy {
  margin: 0;
  grid-column: span 3;
}
@media (max-width: 575px) {
  .contacts__form .form__privicy {
    margin-top: 10px;
    grid-column: unset;
  }
}
.contacts__form-wrapper {
  padding: 30px;
  background: white;
}
@media (max-width: 767px) {
  .contacts__form-wrapper {
    padding: 20px 15px;
  }
}
.contacts__form-title {
  margin: 0 0 20px;
  color: #1F1F1F;
  font-size: 28px;
  font-weight: 300;
}
.contacts__phone {
  display: inline-grid;
  width: fit-content;
  text-decoration: none;
  color: white;
  font-size: 22px;
  font-weight: 700;
  grid-template-columns: 20px auto;
  gap: 8px;
}
.contacts__phone:hover {
  color: #B1021B;
}
.contacts__phone-icon {
  width: 20px;
  height: 20px;
  fill: #B1021B;
}
.contacts__worktime {
  display: inline-flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  font-weight: 400;
}
.contacts__worktime-subtext {
  margin-top: 5px;
  color: #BBBBBB;
  font-size: 16px;
  font-weight: 400;
}

.footer {
  padding: 30px 0;
  background: #1F1F1F;
}
@media (max-width: 575px) {
  .footer {
    padding: 20px 0;
  }
}
.footer__container {
  display: flex;
  justify-content: center;
}

.modal {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: grid;
  visibility: hidden;
  overflow: auto;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 60px 16px;
  transition: 0.1s ease-out;
  opacity: 0;
  background: rgba(31, 31, 31, 0.8431372549);
  justify-items: center;
}
.modal.active {
  visibility: visible;
  transition: 0.2s ease-in;
  opacity: 1;
}
.modal.active .modal-item {
  transition: 0.2s ease-in;
  transform: translateY(0);
}
.modal--pricelist .modal__item {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
@media (max-width: 575px) {
  .modal--pricelist .modal__item {
    grid-template-columns: 1fr;
  }
}
.modal__item {
  position: relative;
  width: 100%;
  max-width: 905px;
  padding: 40px;
  transition: 0.1s ease-out;
  background: white;
  column-gap: 30px;
}
@media (max-width: 767px) {
  .modal__item {
    padding: 30px 20px;
  }
}
.modal__item-content {
  display: flex;
  align-items: start;
  flex-direction: column;
  row-gap: 20px;
}
.modal__close {
  position: absolute;
  top: -34px;
  right: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background: none;
}
.modal__close-icon {
  width: 100%;
  height: 100%;
  fill: white;
}
.modal__title {
  margin: 0 0 20px;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
}
@media (max-width: 767px) {
  .modal__title {
    margin-bottom: 0;
    font-size: 24px;
    line-height: normal;
  }
}
.modal__subtitle {
  display: inline-block;
  margin-top: -15px;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 300;
}
@media (max-width: 767px) {
  .modal__subtitle {
    margin-top: 0;
    font-size: 20px;
  }
}
.modal__pricelist {
  width: 100%;
  padding: 0 30px 20px;
  border: 1px solid #BBBBBB;
}
@media (max-width: 767px) {
  .modal__pricelist {
    padding: 0 15px 15px;
  }
}
.modal__pricelist-item {
  display: grid;
  align-content: end;
  height: 58px;
  padding: 0 0 10px;
  border-bottom: 1px solid #BBBBBB;
  grid-template-columns: 1fr 50px;
}
.modal__pricelist-item:last-of-type {
  border: none;
}
.modal__image {
  height: calc(100% + 80px);
  margin-top: -40px;
  margin-bottom: -40px;
}
@media (max-width: 767px) {
  .modal__image {
    height: calc(100% + 60px);
    margin-top: -30px;
    margin-bottom: -30px;
  }
}
@media (max-width: 575px) {
  .modal__image {
    display: none;
  }
}
.modal__image--washer {
  background: url(../images/modal__image--washer.jpg) repeat-y center 50%/contain;
}
.modal__image--fridge {
  background: url(../images/modal__image--fridge.jpg) repeat-y center 400px/contain;
}
.modal__image--dishwasher {
  background: url(../images/modal__image--dishwasher.jpg) repeat-y center 50%/contain;
}
.modal__image--hob {
  background: url(../images/modal__image--hob.jpg) repeat-y center 50%/contain;
}
.modal__image--coffeemachine {
  background: url(../images/modal__image--coffeemachine.jpg) repeat-y center 50%/contain;
}
.modal__image--oven {
  background: url(../images/modal__image--oven.jpg) repeat-y center 50%/contain;
}
.modal__button {
  margin-left: auto;
}