@mixin xl
  @media (max-width: 1399px)
    @content

@mixin lg
  @media (max-width: 1199px)
    @content

@mixin md
  @media (max-width: 991px)
    @content

@mixin sm
  @media (max-width: 767px)
    @content

@mixin xs
  @media (max-width: 575px)
    @content



