@import sass/variables
@import sass/mixins
@import sass/fonts
@import sass/normalize

body
  color: #1F1F1F

  font: 400 18px 'HelveticaNeueCyr'

section
  padding: 120px 0

  @include sm
    padding: 60px 0
p
  margin: 0

.icon
  display: inline-block

  width: 24px
  height: 24px

.h1,
.h2
  position: relative

  margin: 0

  font-size: 52px
  font-weight: 700
  line-height: 60px

  @include sm
    font-size: 32px
    line-height: 40px

  &::after
    padding-left: 10px

    content: '––'

    color: $accent-color

    -webkit-text-stroke: 2px $accent-color

.h2
  font-size: 48px
  line-height: 48px

.container
  width: 100%
  max-width: 1332px
  margin: 0 auto
  padding: 0 16px

.input
  height: 53px
  padding: 0 20px

  border: 1px solid $lighter-color
  border-radius: 0
  background: transparent

  font-size: 14px
  font-weight: 400

  &::placeholder
    opacity: 1
    color: $lighter-color

.button
  height: 53px
  padding: 0 32px

  transition: .1s ease-out
  text-transform: uppercase

  color: white
  border: none
  background: $accent-color

  font-size: 14px
  font-weight: 700

  &:hover
    transition: .2s ease-in

    filter: contrast(1.3)

.form
  display: grid

  grid-template-columns: 35% auto 35%
  @include sm
    grid-template-columns: 55% 45%
  @include xs
    grid-template-columns: 1fr

  &__button
    margin-left: -1px

    @include xs
      margin-top: -1px
      margin-left: 0

  &__privicy
    align-self: center

    margin-left: 20px

    color: $darker-color

    font-size: 12px
    font-weight: 300
    line-height: 14px

    @include sm
      margin-top: 10px
      margin-left: 0

      grid-column: 1 / 3
    @include xs
      grid-column: unset
.header
  position: relative
  z-index: 2

  padding: 20px 0

  color: white
  background: $text-color

  @include sm
    padding: 0

  &__container
    display: grid

    grid: "logo menu menu phone" 50px "logo menu menu phone" 50px / 1fr 1fr 1fr 1fr
    @include lg
      grid: "logo menu phone" 50px "logo menu phone" 50px / 1fr 40% 1fr
    @include sm
      grid: "logo phone" 35px "logo phone" 35px / auto auto

  &__cell
    // Квантовые вычесления, почти деление на ноль
    // Это бордер коллапс короче
    width: calc(100% + 1px)
    height: calc(100% + 1px)
    margin: -2px

    border: 1px solid $darker-color

    @include sm
      border: none

  &__left
    display: flex
    align-items: center
    justify-content: center

    grid-area: logo
    @include sm
      justify-content: start
  
  &__logo
    @include sm
      height: 36px

  &__menu
    display: grid

    grid-area: menu
    grid-template-columns: repeat(2, 1fr)
    @include sm
      display: none

    &-item
      padding: 0 20px

      transition: .1s ease-out
      text-align: center
      white-space: nowrap
      text-decoration: none

      color: white

      font-size: 16px
      font-weight: 300
      line-height: 50px

      &:hover
        transition: .2s ease-in

        color: $lighter-color

  &__right
    display: flex
    align-items: center

    padding-right: 32px

    justify-items: flex-end
    grid-area: phone
    @include md
      justify-content: center

      padding: 0

  &__phone
    display: grid

    margin-left: auto

    gap: 5px
    grid: "icon phone" "icon text" / 20px auto
    @include md
      margin-left: 0

      grid: "icon phone" "icon text" / auto
    @include sm
      margin-left: auto

    &-icon
      width: 20px
      height: 20px
      margin-top: 2px

      fill: $accent-color
      grid-area: icon
      @include md
        display: none

    &-link
      transition: .1s ease-out
      text-decoration: none

      color: white

      font-size: 22px
      font-weight: 700
      line-height: 22px

      grid-area: phone

      &:hover
        transition: .2s ease-in

        color: $accent-color

    &-text
      font-size: 16px
      font-weight: 300
      line-height: 16px

      grid-area: text
.heroscreen
  position: relative

  overflow: hidden

  padding: 160px 0

  background: url(../images/heroscreen__background.jpg) no-repeat center / cover

  @include md
    padding: 60px 0

  &__container
    display: grid

    grid-template-columns: 7fr 5fr
    @include md
      grid-template-columns: 1fr

  &__item
    padding: 30px

    background: white

    @include sm
      padding: 15px

  &__image
    position: absolute
    bottom: 0

    height: 100%

    &-wrapper
      display: flex
      justify-content: center

      @include md
        display: none

  &__title
    margin-bottom: 5px

  &__subtitle
    display: inline-block

    margin-bottom: 30px

    font-size: 28px
    font-weight: 300
    line-height: 28px

    @include sm
      margin-bottom: 16px

      font-size: 20px
      line-height: 24px
.advantages
  padding: 60px 0 0

  @include sm
    padding-top: 30px

  &__container
    display: grid

    grid-template-columns: repeat(3, 1fr)
    @include sm
      grid-template-columns: 1fr 1fr
    @include xs
      grid-template-columns: 1fr

  &__item
    display: grid
    align-items: center

    width: calc(100% + 1px)
    height: calc(100% + 1px)
    margin: -2px
    padding: 30px

    border: 1px solid $lighter-color

    gap: 20px
    grid-template-columns: auto 1fr
    @include md
      padding: 15px

      gap: 10px
    
    &:last-of-type
      @include sm
        grid-column: 1 / 3
      @include xs
        grid-column: unset

    &-icon
      width: 66px
      height: 66px

      @include md
        width: 36px
        height: 36px
.pricelist
  &__container
    display: grid

    gap: 30px
    @include sm
      gap: 15px

  &__main
    display: grid

    grid: "first third fourth fourth" "second third fifth sixth" / 1fr 1fr 1fr 1fr
    column-gap: 24px
    row-gap: 20px
    @include lg
      grid: "first third" "second third" "fourth fourth" "sixth fifth"  / 1fr 1fr
    @include sm
      grid: "first second" "third fourth" "fifth sixth" / 1fr 1fr
      gap: 16px
    @include xs
      grid: "first" "second" "third" "fourth" "fifth" "sixth" / 1fr

  &__item
    display: flex
    flex-direction: column

    padding: 20px 0 0 20px

    cursor: pointer
    transition: .1s ease-out

    background: url(../images/pricelist__item-image--background.svg) no-repeat right bottom / 20%
    background-color: $text-color

    row-gap: 30px
    @include sm
      overflow: hidden
      align-items: end

      max-height: 200px

    &:hover
      transition: .3s ease-in

      background: url(../images/pricelist__item-image--background.svg) no-repeat right bottom / 80%
      background-color: $accent-color

    &--washer
      grid-area: first

    &--dishwasher
      grid-area: second

    &--fridge
      grid-area: third

    &--coffeemachine
      grid-area: fourth

    &--hob
      grid-area: fifth

    &--oven
      grid-area: sixth

    &-title
      display: grid
      align-items: center

      width: 100%
      margin: 0

      color: white

      font-size: 20px
      font-weight: 500

      grid-template-columns: auto 1fr
      gap: 10px

      &-line
        width: auto
        height: 1px

        background: rgba(255, 255, 255, 0.3)

    &-image
      margin-top: auto
      margin-left: auto

      @include xs
        margin-left: 100px
.cta
  padding: 0

  color: white
  background: url(../images/cta__background.jpg) no-repeat center / cover

  &__container
    display: grid

    grid-template-columns: 1fr 1fr
    gap: 24px
    @include sm
      grid-template-columns: 1fr

  &__image
    position: absolute
    
    &-wrapper
      position: relative

      display: flex
      align-items: end
      justify-content: center

      @include sm
        display: none

  &__main
    padding: 120px 0

    @include sm
      padding: 60px 0

  &__title
    margin-bottom: 5px

  &__subtitle
    display: inline-block

    margin-bottom: 40px

    font-size: 36px
    font-weight: 400

    @include md
      font-size: 28px
    @include sm
      font-size: 20px

  &__form
    grid-template-columns: 55% auto
    row-gap: 10px
    @include sm
      row-gap: 0

    .form__input
      color: white

    .form__privicy
      margin: 0

      color: $lighter-color

      grid-column: span 3
      @include sm
        margin-top: 10px

        grid-column: inherit
.about
  overflow: hidden

  padding-bottom: 0

  &__container
    display: grid

    grid: "sidebar main main main" / 1fr 1fr 1fr 1fr
    column-gap: 24px
    @include md
      grid: "sidebar" "main" / 1fr

  &__sidebar
    grid-area: sidebar
    @include md
      margin-bottom: 20px

  &__main
    grid-area: main

  &__title
    margin-bottom: 40px

    @include sm
      margin-bottom: 10px

  &__advantages
    @include md
      display: grid

      grid-template-columns: 1fr 1fr

    &-item
      display: grid
      align-items: center

      max-width: 276px
      padding: 16px 0

      border-bottom: 1px solid $lighter-color

      column-gap: 10px
      grid-template-columns: 40px 1fr
      @include md
        max-width: unset
      @include xs
        font-size: 16px

        grid-template-columns: 30px 1fr

      &:nth-of-type(n+3)
        @include md
          border-bottom: none

      &-icon
        width: 40px
        height: 40px

        @include xs
          width: 30px
          height: 30px

  &__images
    display: flex

    margin-bottom: 40px

    column-gap: 24px
    @include md
      display: grid

      grid-template-columns: 1fr 1fr
    @include xs
      grid-template-columns: 1fr

    &-item
      max-width: 416px

      @include md
        width: 100%
      @include xs
        max-width: unset
        height: 250px

        object-fit: cover
        object-position: top

      &:last-of-type
        @include lg
          display: none
      
      &:nth-of-type(2)
        @include xs
          display: none

  &__text
    width: 100vw
    padding-bottom: 40px

    border-bottom: 1px solid $lighter-color

    @include lg
      width: calc(100% + 15px)
    @include md
      width: 100%

      border-bottom: none
    
    b
      display: inline-block

      margin-bottom: 10px

    br
      @include lg
        display: none
.guarantee
  @include sm
    padding-top: 0
  &__item
    display: grid
    overflow: hidden

    border: 1px solid $lighter-color

    grid-template-columns: 45% 50% 5%
    @include lg
      grid-template-columns: 1fr 1fr
    @include sm
      grid-template-columns: 1fr

  &__content
    padding: 120px 50px

    @include sm
      padding: 20px 20px

  &__subtitle
    display: inline-block

    margin-bottom: 35px

    font-size: 28px
    font-weight: 300

    @include sm
      margin-bottom: 16px

  &__image
    height: 100%

    @include sm
      display: none
.contacts
  color: white
  background: url(../images/contacts__background.jpg) no-repeat center / cover

  &__main
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    max-width: 550px

    row-gap: 30px
    @include sm
      column-gap: 20px
      row-gap: 20px

  &__title
    margin-bottom: 30px

  &__form
    grid-template-columns: 55% 45%
    row-gap: 10px
    @include xs
      row-gap: unset

    .form__privicy
      margin: 0

      grid-column: span 3
      @include xs
        margin-top: 10px

        grid-column: unset

    &-wrapper
      padding: 30px

      background: white

      @include sm
        padding: 20px 15px

    &-title
      margin: 0 0 20px

      color: $text-color

      font-size: 28px
      font-weight: 300

  &__phone
    display: inline-grid

    width: fit-content

    text-decoration: none

    color: white

    font-size: 22px
    font-weight: 700

    grid-template-columns: 20px auto
    gap: 8px

    &:hover
      color: $accent-color

    &-icon
      width: 20px
      height: 20px

      fill: $accent-color

  &__worktime
    display: inline-flex
    flex-direction: column

    color: white

    font-size: 18px
    font-weight: 400

    &-subtext
      margin-top: 5px

      color: $lighter-color

      font-size: 16px
      font-weight: 400
.footer
  padding: 30px 0

  background: $text-color

  @include xs
    padding: 20px 0

  &__container
    display: flex
    justify-content: center

.modal
  position: fixed
  z-index: 99
  top: 0
  left: 0

  display: grid
  visibility: hidden
  overflow: auto
  align-items: center

  width: 100%
  height: 100%
  padding: 60px 16px

  transition: .1s ease-out

  opacity: 0
  background: #1f1f1fd7

  justify-items: center

  &.active
    visibility: visible

    transition: .2s ease-in

    opacity: 1

    & .modal
      &-item
        transition: .2s ease-in
        transform: translateY(0)

  &--pricelist .modal
    &__item
      display: grid

      grid-template-columns: 2fr 1fr
      @include xs
        grid-template-columns: 1fr

  &__item
    position: relative

    width: 100%
    max-width: 905px
    padding: 40px

    transition: .1s ease-out

    background: white

    column-gap: 30px
    @include sm
      padding: 30px 20px

    &-content
      display: flex
      align-items: start
      flex-direction: column

      row-gap: 20px

  &__close
    position: absolute
    top: -34px
    right: 0

    width: 24px
    height: 24px
    padding: 0

    border: none
    background: none

    &-icon
      width: 100%
      height: 100%

      fill: white

  &__title
    margin: 0 0 20px

    font-size: 36px
    font-weight: 700
    line-height: 36px

    @include sm
      margin-bottom: 0

      font-size: 24px
      line-height: normal

  &__subtitle
    display: inline-block

    margin-top: -15px
    margin-bottom: 20px

    font-size: 28px
    font-weight: 300

    @include sm
      margin-top: 0

      font-size: 20px

  &__pricelist
    width: 100%
    padding: 0 30px 20px

    border: 1px solid $lighter-color

    @include sm
      padding: 0 15px 15px

    &-item
      display: grid
      align-content: end

      height: 58px
      padding: 0 0 10px

      border-bottom: 1px solid $lighter-color

      grid-template-columns: 1fr 50px

      &:last-of-type
        border: none

  &__image
    height: calc(100% + 80px)
    margin-top: -40px
    margin-bottom: -40px

    @include sm
      height: calc(100% + 60px)
      margin-top: -30px
      margin-bottom: -30px
    @include xs
      display: none

    &--washer
      background: url(../images/modal__image--washer.jpg) repeat-y center 50% / contain
    
    &--fridge
      background: url(../images/modal__image--fridge.jpg) repeat-y center 400px / contain

    &--dishwasher
      background: url(../images/modal__image--dishwasher.jpg) repeat-y center 50% / contain
    
    &--hob
      background: url(../images/modal__image--hob.jpg) repeat-y center 50% / contain
    
    &--coffeemachine
      background: url(../images/modal__image--coffeemachine.jpg) repeat-y center 50% / contain
    
    &--oven
      background: url(../images/modal__image--oven.jpg) repeat-y center 50% / contain

  &__button
    margin-left: auto
