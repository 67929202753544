*
  box-sizing: border-box

  outline: none !important

html
  font-family: sans-serif

  text-size-adjust: 100%

body
  margin: 0

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary
  display: block

audio,
canvas,
progress,
video
  display: inline-block

audio:not([controls])
  display: none

  height: 0

progress
  vertical-align: baseline

template,
[hidden]
  display: none

a
  background-color: transparent

  text-decoration-skip: objects

a:active,
a:hover
  outline-width: 0

abbr[title]
  text-decoration: underline
  text-decoration: underline dotted

  border-bottom: none

b,
strong
  font-weight: inherit

b,
strong
  font-weight: bolder

dfn
  font-style: italic

h1
  margin: 0.67em 0


  font-size: 2em

mark
  color: #000
  background-color: #ff0

small
  font-size: 80%

sub,
sup
  position: relative

  vertical-align: baseline

  font-size: 75%
  line-height: 0

sub
  bottom: -0.25em

sup
  top: -0.5em

img
  border-style: none

svg:not(:root)
  overflow: hidden

code,
kbd,
pre,
samp
  font-family: monospace, monospace
  font-size: 1em

figure
  margin: 1em 40px

hr
  overflow: visible

  box-sizing: content-box
  height: 0 

button
  cursor: pointer

button,
input,
select,
textarea
  margin: 0

  outline: none

  font: inherit

optgroup
  font-weight: bold

button,
input
  overflow: visible

button,
select
  text-transform: none

button,
html [type="button"],
[type="reset"],
[type="submit"]
  appearance: button

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner
  padding: 0

  border-style: none

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring
  outline: 1px dotted ButtonText

fieldset
  margin: 0 2px
  padding: 0.35em 0.625em 0.75em

  border: 1px solid #c0c0c0

legend
  display: table

  box-sizing: border-box
  max-width: 100%
  padding: 0

  white-space: normal

  color: inherit

textarea
  overflow: auto

[type="checkbox"],
[type="radio"]
  box-sizing: border-box
  padding: 0

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button
  height: auto

[type="search"]
  outline-offset: -2px

  appearance: textfield

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration
  appearance: none

::-webkit-input-placeholder
  opacity: 0.54
  color: inherit

::-webkit-file-upload-button
  font: inherit

  -webkit-appearance: button
